html,
body {
  width: 100%;
  height: 100% !important;

  @apply text-gray_06 text-16;
}

html {
  body {
    &.swal2-height-auto {
      height: 100% !important;
    }
  }
}

/* button */
.button {
  display: flex;
  width: 100%;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  font-weight: 700;
  cursor: pointer;

  @apply text-white  text-16 leading-24;
  @apply bg-blue disabled:bg-gray_04 active:bg-blue disabled:text-gray_08;
  @apply border rounded-8 border-0;

  &.back {
    width: 24px;
    height: 24px;
    border-radius: 0;
    background: url(../../images/button_back.svg) no-repeat;
    text-indent: -999px;
    overflow: hidden;
  }

  &.blue_01 {
    @apply bg-blue_01 disabled:bg-gray_04 active:bg-blue_01;
  }

  &.blue_02 {
    @apply bg-blue_02 disabled:bg-gray_04 active:bg-blue_02;
  }

  &.signup_01 {
    border-radius: 8px;
    border: 1px solid rgba(147, 158, 173, 0.5);
    background: #fff;
    color: #5a6f8b;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &:before {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background: url(../../images/icon_signup_01.svg) no-repeat;
    }
  }

  &.signup_02 {
    border-radius: 8px;
    border: 1px solid rgba(147, 158, 173, 0.5);
    background: #fff;
    color: #5a6f8b;
    font-size: 16px;
    font-weight: 700;

    &:before {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background: url(../../images/icon_signup_02.svg) no-repeat;
    }
  }

  &.signup_03 {
    border-radius: 8px;
    background: #2f66f6;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    border-radius: 8px;
  }
}

/* arrow */
.arrow {
  width: 24px;
  height: 24px;
  display: block;
  background: url(../../images/arrow.svg) no-repeat;
  background-size: 24px;
  background-position: 0;
  overflow: hidden;
  text-indent: -999px;
}

/* checkbox */
input[type="checkbox"] {
  display: none;

  & + label {
    width: 19px;
    height: 19px;
    display: block;
    background: url(../../images/checkbox_off.svg) no-repeat;
    background-size: 19px;
    background-position: 0;
    overflow: hidden;
    text-indent: -999px;
  }

  &:checked + label {
    background: url(../../images/checkbox_on.svg) no-repeat;
    background-size: 19px;
    background-position: 0;
  }
}

/* input field */
div.input {
  display: flex;
  width: 100%;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;

  @apply bg-white border border-1 border-gray_01 rounded-8;

  > label {
    display: block;
    width: calc(100% - 40px);
    font-weight: 400;
    letter-spacing: 0.04px;

    @apply text-gray_02 text-12 leading-16;
  }

  > input,
  > span {
    display: block;
    width: calc(100% - 30px);
    font-style: normal;
    font-weight: 400;
    border: 0;

    @apply text-black text-16 leading-24;
    @apply focus:outline-none placeholder:text-gray_03;
  }

  > textarea {
    display: block;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    border: 0;
    @apply text-black text-16 leading-24;
    @apply focus:outline-none placeholder:text-gray_03;
  }

  & + p {
    font-weight: 400;
    padding-top: 8px;

    @apply text-red text-13 leading-20;
  }

  &.on {
    background: url(../../images/input_on.svg) no-repeat center right 16px;
    @apply border border-1 border-blue;
  }

  &.error,
  &.duplicate {
    background: url(../../images/input_error.svg) no-repeat center right 16px;
    @apply border border-1 border-red;

    > label {
      @apply text-red;
    }
  }
}

/* swal */
.swal_text {
  @apply text-16 leading-24;
}

div:where(.swal2-container) div:where(.swal2-actions) {
  margin-top: 20px !important;
  outline: none !important; /* 포커스 있을 때 보더 없애기 */
  border: 0 !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #ee7575 !important;
  font-size: 16px !important;
  outline: none !important; /* 포커스 있을 때 보더 없애기 */
  border: 0 !important;
}

button:where(.swal2-styled).swal2-confirm:focus-visible {
  box-shadow: none !important;
}

div:where(.swal2-container) button:where(.swal2-styled) {
  margin: 0 !important;
  padding: 5px 20px !important;
  outline: none !important; /* 포커스 있을 때 보더 없애기 */
  border: 0 !important;
}

/* loading */
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ffffffb7;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    font-size: 16px;
    text-align: center;
    background: url(../../images/loading.gif) top center no-repeat;
    padding-top: 100px;
    background-size: 100px;
  }
}

/* table */
table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 8px;
    border: 1px solid var(--gray_01);
    text-align: center;
  }

  th {
    background: var(--gray_04);
    font-size: 14px;
    font-weight: 700;
    color: var(--gray_02);
  }

  td {
    font-size: 16px;
    font-weight: 400;
    color: var(--black);
  }

  tr {
    &:hover {
      background: var(--gray_04);
    }
  }
}

footer {
  width: 100%;
  background: #fafafa;
  color: #7a7a7a;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  padding: 12px 16px;
  margin-top: 10px;
}

div#root {
  width: 100%;
  height: 100%;
}

/* main */
div.main {
  min-height: 100%;
  max-width: 720px;
  margin: 0 auto;
  padding: 61px 0 0;
  background: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > h1 {
    width: 115px;
    height: 148px;
    background: url(../../images/logo.svg) no-repeat;
    margin: 0 auto 72px;
    overflow: hidden;
    text-indent: -999px;
  }

  > div {
    padding: 0 16px;

    &.login {
      width: 100%;
      margin-bottom: 38px;

      form {
        width: 100%;

        ul {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          gap: 10px;

          li {
            width: 100%;
          }
        }
      }
    }

    &.signup {
      flex: 1;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      > a {
        flex: 1;
      }
    }
  }
}

/* sub */
div.sub {
  width: 100%;
  height: 100%;
  max-width: 720px;
  margin: 0 auto;

  > header {
    height: 56px;
    line-height: 56px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    position: relative;
    @apply text-black_01;

    > a {
      position: absolute;
      top: 16px;
      left: 16px;
    }
  }

  > div:nth-child(2n) {
    height: calc(100% - 56px);
    width: 100%;
    padding: 16px 16px 27.5px;
    overflow: hidden;
    overflow-y: auto;
  }
}

div.signup {
  > form {
    height: calc(100% - 56px);

    > ul {
      height: calc(100% - 86px);
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      overflow: auto;

      > li {
        width: 100%;
      }
    }

    > footer {
      width: 100%;
      height: 86px;
      box-shadow: 0px -4px 13px 0px rgba(0, 0, 0, 0.08);
      padding: 8px 16px 0 16px;
      z-index: 1;
      position: absolute;
      @apply bg-white;
    }
  }
}

div.complete {
  > p {
    color: var(--blue);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding-top: 232px;
    background: url(../../images/complete.svg) no-repeat top center;
    text-align: center;
    margin-top: 80px;
  }
}

div.list {
  overflow: auto !important;

  > table {
    min-width: 600px;

    th,
    td {
      font-size: 12px;
    }

    > tbody {
      tr:nth-child(4n + 1),
      tr:nth-child(4n + 2) {
        td {
          background: var(--gray_03);
        }
      }
    }
  }
}

div.terms {
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    > h2 {
      color: rgba(0, 0, 0, 0.67);
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      @apply mb-17;
    }

    > div {
      width: 100%;
      flex: 1;

      > ul {
        border-radius: 8px;
        border: 1px solid rgba(147, 158, 173, 0.5);
        @apply bg-white;

        > li {
          height: 53px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 16px;
          border-bottom: 1px solid rgba(147, 158, 173, 0.5);

          > label {
            margin-left: 12px;
            padding-left: 35px;
            @apply text-gray_07;
            font-size: 16px;
            font-weight: 500;
            height: 24px;
            line-height: 24px;
            flex: 1;
            text-indent: 0;
            background-position: 0 2px;
          }

          > button {
            @apply mr-7;
            cursor: pointer;
          }

          &:first-child {
            > label {
              @apply text-blue_03;
            }
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.popup-overlay {
  @apply bg-white;

  > .popup-content {
    max-width: 720px;
    height: 100%;
    margin: 0 auto;
  }
}

.termsModal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0;
  flex-direction: column;

  > button.close {
    width: 34px;
    height: 34px;
    background: url(../../images/modal_close.svg) no-repeat;
    background-size: 34px;
    background-position: 0;
    display: block;
    overflow: hidden;
    text-indent: -999px;
    margin: 10px 0 0 16px;
  }

  > div {
    &.header {
      @apply mt-28 text-black_02 ml-16 mb-30;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px; /* 100% */
      text-indent: 16px;
    }

    &.content {
      flex: 1;
      overflow: auto;
      padding: 0 16px 13px;

      > h2 {
        @apply text-black_03 mb-24;
        font-size: 15px;
        font-weight: 700;
        line-height: 24px;
      }

      > dl {
        font-size: 15px;
        line-height: 24px;
        @apply text-black_03;

        > dt {
          font-weight: 700;
        }

        > dd {
          font-weight: 500;
          @apply mb-24;

          > span {
            display: block;
            @apply mb-24;
          }

          > ul {
            > li {
              > ul {
                @apply ml-10;
              }

              > table {
                th {
                  @apply bg-blue_04 text-center font-normal text-14;
                  border: 1px solid rgba(123, 135, 148, 0.6);

                  &:first-child {
                    width: 40%;
                  }
                }

                td {
                  @apply text-left font-normal text-15;
                  border: 1px solid rgba(123, 135, 148, 0.6);
                }
              }
            }
          }
        }
      }
    }
  }

  > footer {
    width: 100%;
    height: 93px;
    box-shadow: 0px -4px 13px 0px rgba(0, 0, 0, 0.08);
    padding: 15.5px 16px 0 16px;
  }
}

div.home {
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: #f8f9fa;

  h1 {
    width: 100%;
    height: 79px;
    background: url(../../images/logo_home.svg) no-repeat center 17px;
    background-size: 113px 28px;
    overflow: hidden;
    text-indent: -999px;
  }

  div.coin-box {
    width: calc(100% - 32px);
    height: 201px;
    padding: 24px 0 0 18px;
    margin: 0 16px 32px;
    border-radius: 10px;
    background: #1e1671;
    position: relative;
    overflow: hidden;

    h2 {
      color: #fff;
      font-size: 15px;
      font-weight: 100;
      margin-bottom: 22px;

      span {
        font-weight: 400;
      }
    }

    div {
      margin-bottom: 22px;

      h3 {
        color: #fff;
        font-size: 10px;
        font-weight: 600;
        margin-bottom: 3px;
      }

      p {
        height: 34px;
        display: flex;
        justify-content: flex-start;
        align-items: end;
        gap: 4px;

        span {
          display: block;
          color: #fff;
          font-weight: 600;

          &:first-child {
            line-height: 34px;
            font-size: 28px;
            padding-left: 40px;
            background: url(../../images/icon_coin.svg) no-repeat 0 4px;
            background-size: 26px;
          }

          &:last-child {
            font-size: 10px;
            font-weight: 600;
          }
        }
      }
    }

    ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 31px;

      li {
        position: relative;

        h3 {
          height: 12px;
          line-height: 12px;
          color: #fff;
          font-size: 10px;
          font-weight: 600;
          margin-bottom: 2px;
        }

        span {
          display: block;
          height: 22px;
          line-height: 22px;
          color: var(--Bg, #fff);
          font-size: 18px;
          font-weight: 400;
        }

        &:last-child {
          &:after {
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: 100%;
            background: rgba(248, 249, 250, 0.5);
            top: 0;
            left: -15px;
          }
        }
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100px;
      height: 87px;
      background: url(../../images/icon-coin-box-1.svg) no-repeat;
      background-size: 100%;
      top: 0;
      right: 0;
      z-index: 1;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 55px;
      height: 28px;
      background: url(../../images/icon-coin-box-2.svg) no-repeat;
      background-size: 100%;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }

  div.point-box {
    padding: 0 16px 32px;
    width: 100%;

    h2 {
      color: #111;
      font-size: 20px;
      font-weight: 500;
      line-height: 145%;
      letter-spacing: -1px;
      margin-bottom: 10px;
    }

    div {
      height: 74px;
      border-radius: 10px;
      border: 1px solid #cfcfcf;
      background: #fff;
      padding: 16px 0 0 79px;
      background: url(../../images/icon_mypoint.svg) no-repeat 16px;

      > span {
        display: block;
        color: #666;
        font-size: 13px;
        font-weight: 500;
        line-height: 102%; /* 13.26px */
      }

      p {
        color: #2f66f6;
        font-size: 24px;
        font-weight: 700;
        line-height: 38px; /* 158.333% */

        span {
          margin-left: 5px;
          color: #2f66f6;
          font-size: 20px;
          font-weight: 400;
          line-height: 38px;
        }
      }
    }
  }

  div.merchant-box {
    padding: 0 16px 97px;
    width: 100%;
    flex: 1;

    > h3 {
      color: var(--Base-100, #111);
      font-family: Inter;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: -1px;
      margin-bottom: 10px;
    }
  }
}

nav.menu {
  background: #fff;
  width: 100%;
  height: 78px;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #d7d9e4;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    li {
      height: 100%;
      flex: 1;

      a {
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        padding-top: 48px;
        color: #696f8c;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;

        &.menu_01 {
          background: url(../../images/icon_menu_01.svg) no-repeat center 16px;
          background-size: 24px;
        }

        &.menu_02 {
          background: url(../../images/icon_menu_02.svg) no-repeat center 16px;
          background-size: 24px;
        }

        &.menu_03 {
          background: url(../../images/icon_menu_03.svg) no-repeat center 16px;
          background-size: 24px;
        }

        &.menu_04 {
          background: url(../../images/icon_menu_04.svg) no-repeat center 16px;
          background-size: 24px;
        }

        &.menu_05 {
          background: url(../../images/icon_menu_05.svg) no-repeat center 16px;
          background-size: 24px;
        }

        &.on {
          color: #2f66f6;

          &.menu_01 {
            background: url(../../images/icon_menu_01_on.svg) no-repeat center 16px;
            background-size: 24px;
          }

          &.menu_02 {
            background: url(../../images/icon_menu_02_on.svg) no-repeat center 16px;
            background-size: 24px;
          }

          &.menu_03 {
            background: url(../../images/icon_menu_03_on.svg) no-repeat center 16px;
            background-size: 24px;
          }

          &.menu_04 {
            background: url(../../images/icon_menu_04_on.svg) no-repeat center 16px;
            background-size: 24px;
          }

          &.menu_05 {
            background: url(../../images/icon_menu_05_on.svg) no-repeat center 16px;
            background-size: 24px;
          }
        }
      }
    }
  }
}

div.mypage {
  min-height: 100%;

  > div.content {
    padding: 24px 16px 97px;

    > div {
      &.point-box {
        width: 100%;
        margin-bottom: 17px;
        height: 126px;
        padding: 0;
        border-radius: 10px;
        border: 1px solid #cfcfcf;
        background: #fff;
        overflow: hidden;

        h2 {
          height: 46px;
          background: #eef2f9;
          padding: 15px;
          color: #000;
          font-size: 15px;
          font-weight: 600;

          span {
            color: #666;
            font-size: 15px;
            font-weight: 400;
          }
        }

        div {
          height: 78px;
          padding: 16px 0 0 79px;
          background: url(../../images/icon_mypoint.svg) no-repeat 16px;

          > span {
            display: block;
            color: #666;
            font-size: 13px;
            font-weight: 500;
            line-height: 102%; /* 13.26px */
          }

          p {
            color: #2f66f6;
            font-size: 24px;
            font-weight: 700;
            line-height: 38px; /* 158.333% */

            span {
              margin-left: 5px;
              color: #2f66f6;
              font-size: 20px;
              font-weight: 400;
              line-height: 38px;
            }
          }
        }
      }

      &.menu-list {
        h3 {
          color: #696f8c;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px; /* 114.286% */
          margin-bottom: 7px;
        }

        ul {
          border-radius: 8px;
          border: 1px solid rgba(147, 158, 173, 0.5);
          background: #fff;

          li {
            height: 57px;
            border-bottom: 1px solid rgba(147, 158, 173, 0.5);

            &:last-child {
              height: 56px;
              border-bottom: 0;
            }

            a {
              display: block;
              width: 100%;
              height: 100%;
              padding: 14px 8px 14px 53px;
              line-height: 28px;
              color: #7b8794;
              font-size: 16px;
              font-weight: 300;
              height: 56px;

              &.mypage_01 {
                background: url(../../images/icon_profile.svg) no-repeat 13px 15px;
                background-size: 24px;
              }

              &.mypage_02 {
                background: url(../../images/icon_logout.svg) no-repeat 13px 15px;
                background-size: 24px;
              }
            }
          }
        }
      }

      &.btn-list {
        margin-bottom: 22px;

        h3 {
          color: #696f8c;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px; /* 114.286% */
          margin-bottom: 7px;
        }

        ul {
          li {
            button {
              display: block;
              width: 100%;
              height: 56px;
              line-height: 56px;
              color: #fff;
              font-size: 16px;
              font-weight: 400;

              &.link_copy {
                border-radius: 8px;
                background: #2f66f6;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                &:after {
                  content: "";
                  display: block;
                  width: 20px;
                  height: 20px;
                  background: url(../../images/icon_link_copy.svg) no-repeat;
                }
              }
            }
          }
        }
      }
    }

    > ul.link-list {
      margin-bottom: 24px;

      > li + li {
        margin-top: 10px;
      }

      a {
        display: block;
        width: 100%;
        height: 64px;
        border-radius: 8px;
        border: 1px solid rgba(147, 158, 173, 0.5);
        background: #fff;
        padding: 11px 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        color: #242424;
        font-size: 16px;
        font-weight: 400;

        &:before {
          content: "";
          display: block;
          width: 40px;
          height: 40px;
        }

        &.mypage_03 {
          &:before {
            background: url(../../images/icon_org_chart.svg) no-repeat;
            background-size: 40px;
          }
        }

        &.mypage_04 {
          &:before {
            background: url(../../images/icon_merchant.svg) no-repeat;
            background-size: 40px;
          }
        }

        &.mypage_05 {
          &:before {
            background: url(../../images/icon_member.svg) no-repeat;
            background-size: 40px;
          }
        }
      }
    }
  }
}

div.edit {
  > div.content {
    > h2 {
      color: #333;
      font-size: 15px;
      font-weight: 600;
      line-height: 22px; 
    }
  }
}

div.orgchart {
  div.content {
    padding: 36px 0;
    overflow: auto;

    > ul {
      padding: 0 16px;
      width: fit-content;
      margin: 0 auto;
    }
  }

  div.node {
    > dl {
      width: fit-content;
      min-width: 112px;
      height: 84px;
      overflow: hidden;
      margin: 0 auto;
      border-radius: 8px;
      background: #fff;

      > dt {
        padding: 4px 5px;

        > h2 {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          color: #111;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;

          > span {
            position: relative;
            padding-left: 16px;
            text-align: center;
            font-size: 13px;
            font-weight: 400;
            line-height: 21px;
            display: block;

            &:before {
              position: absolute;
              left: 0;
              top: 3px;
              content: "";
              display: block;
              width: 14px;
              height: 14px;
              background: #939ead;
              border-radius: 7px;
              background: url(../../images/icon_org_man.svg) no-repeat 2px 3px;
            }
          }
        }

        > p {
          color: #939ead;
          text-align: center;
          font-size: 13px;
          font-weight: 500;
          line-height: 20px;
        }
      }

      > dd {
        padding: 0 5px;
        height: 29px;
        color: #000;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.52px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;

        &:before {
          content: "P";
          display: block;
          width: 14px;
          height: 14px;
          background: #939ead;
          color: #fff;
          border-radius: 7px;
          color: #fff;
          text-align: center;
          font-size: 12px;
          font-weight: 200;
          line-height: 15px;
          letter-spacing: -0.52px;
          overflow: hidden;
        }
      }

      &.lv {
        border: 3px solid #cebc13;

        > dt {
          border-bottom: 1px solid #cebc13;

          h2 {
            span {
              display: block;
              color: #cebc13;

              &:before {
                background-color: #cebc13;
              }
            }
          }
        }
      }

      &.lv0 {
        border: 3px solid #3480f2;

        > dt {
          border-bottom: 1px solid #3480f2;

          h2 {
            span {
              display: block;
              color: #3480f2;

              &:before {
                background-color: #3480f2;
              }
            }
          }
        }
      }

      &.lv1 {
        border: 3px solid #94c752;

        > dt {
          border-bottom: 1px solid #94c752;

          h2 {
            span {
              display: block;
              color: #94c752;

              &:before {
                background-color: #94c752;
              }
            }
          }
        }
      }

      &.lv2 {
        border: 3px solid #eb9d20;

        > dt {
          border-bottom: 1px solid #eb9d20;

          h2 {
            span {
              display: block;
              color: #eb9d20;

              &:before {
                background-color: #eb9d20;
              }
            }
          }
        }
      }

      &.lv3 {
        border: 3px solid #af58ce;

        > dt {
          border-bottom: 1px solid #af58ce;

          h2 {
            span {
              display: block;
              color: #af58ce;

              &:before {
                background-color: #af58ce;
              }
            }
          }
        }
      }

      &.lv4 {
        border: 3px solid #f782bb;

        > dt {
          border-bottom: 1px solid #f782bb;

          h2 {
            span {
              display: block;
              color: #f782bb;

              &:before {
                background-color: #f782bb;
              }
            }
          }
        }
      }

      &.lv5 {
        border: 3px solid #19ccb4;

        > dt {
          border-bottom: 1px solid #19ccb4;

          h2 {
            span {
              display: block;
              color: #19ccb4;

              &:before {
                background-color: #19ccb4;
              }
            }
          }
        }
      }

      &.lv6 {
        border: 3px solid #406498;

        > dt {
          border-bottom: 1px solid #406498;

          h2 {
            span {
              display: block;
              color: #406498;

              &:before {
                background-color: #406498;
              }
            }
          }
        }
      }

      &.lv7 {
        border: 3px solid #8ee3f2;

        > dt {
          border-bottom: 1px solid #8ee3f2;

          h2 {
            span {
              display: block;
              color: #8ee3f2;

              &:before {
                background-color: #8ee3f2;
              }
            }
          }
        }
      }

      &.lv8 {
        border: 3px solid #64748b;

        > dt {
          border-bottom: 1px solid #64748b;

          h2 {
            span {
              display: block;
              color: #64748b;

              &:before {
                background-color: #64748b;
              }
            }
          }
        }
      }

      &.lv9 {
        border: 3px solid #a7a7a7;

        > dt {
          border-bottom: 1px solid #a7a7a7;

          h2 {
            span {
              display: block;
              color: #a7a7a7;

              &:before {
                background-color: #a7a7a7;
              }
            }
          }
        }
      }
    }
  }
}

/* ref */
div.ref {
  height: 100%;
  min-height: 100%;
  max-width: 720px;
  margin: 0 auto;
  padding: 61px 0 0;
  background: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > h1 {
    width: 115px;
    height: 148px;
    background: url(../../images/logo.svg) no-repeat;
    margin: 0 auto 79px;
    overflow: hidden;
    text-indent: -999px;
  }

  > p {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.16px;
    margin-bottom: 65px;
  }

  > div {
    width: 100%;
    flex: 1;
    padding: 0 16px;
  }
}

div.merchant {
  > form {
    height: calc(100% - 56px);

    > ul {
      height: calc(100% - 86px);
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      overflow: auto;

      > li {
        width: 100%;
      }
    }
  }

  div.content {
    padding: 16px 16px 97px 16px;
    overflow: auto;

    > div.list {
      > div {
        border-radius: 8px;
        border: 1px solid rgba(147, 158, 173, 0.50);
        background: #FFF;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        height: 106px;
        overflow: hidden;
        padding: 9px;
        background: #fff;

        & + div {
          margin-top: 8px;
        }

        > img {
          max-width: 86px; /* 너비는 부모 요소의 100%를 넘지 않음 */
          max-height: 86px; /* 높이는 최대 300px로 제한 */
          width: auto; /* 가로 비율을 유지 */
          height: auto; /* 세로 비율을 유지 */
          object-fit: contain; /* 이미지의 비율을 유지하면서 영역 안에 맞춤 */
          border-radius: 5px;
        } 

        > div {
          height: 86px;
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          gap: 10px;
          flex-direction: column;

          > h3 {
            color: #000;
            font-size: 20px;
            font-weight: 500;
            line-height: 20px; /* 100% */
          }

          > p { 
            height: 28px;
            width: 100%;
            display: -webkit-box;        /* Flexbox의 형식인 블록 컨테이너로 변환 */
            -webkit-box-orient: vertical; /* Flexbox를 수직으로 배치 */
            overflow: hidden;            /* 넘치는 텍스트를 숨김 */
            text-overflow: ellipsis;     /* 넘친 부분에 '...' 표시 */
            -webkit-line-clamp: 2;       /* 2줄까지만 표시 */
            color: #737373;
            font-size: 14px;
            font-weight: 500;
            line-height: 14px; /* 128.571% */
          }

          > a {
            color: #10B981;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px;
          }
        }
      }
    }
  }
}

div.merchant_list {
  > div {
    border-radius: 8px;
    border: 1px solid rgba(147, 158, 173, 0.50);
    background: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    height: 106px;
    overflow: hidden;
    padding: 9px;
    background: #fff;

    & + div {
      margin-top: 8px;
    }

    > div:first-child {
      width: 86px; /* 너비는 부모 요소의 100%를 넘지 않음 */
      height: 86px; /* 높이는 최대 300px로 제한 */
      border-radius: 5px;
      overflow: hidden;
      
      > img {
        max-width: 86px; /* 너비는 부모 요소의 100%를 넘지 않음 */
        max-height: 86px; /* 높이는 최대 300px로 제한 */
        width: auto; /* 가로 비율을 유지 */
        height: auto; /* 세로 비율을 유지 */
        object-fit: contain; /* 이미지의 비율을 유지하면서 영역 안에 맞춤 */
        border-radius: 5px;
      } 
    }

    > div:last-child {
      height: 86px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;
      flex-direction: column;

      > h3 {
        color: #000;
        font-size: 20px;
        font-weight: 500;
        line-height: 20px; /* 100% */
      }

      > p { 
        height: 28px;
        width: 100%;
        display: -webkit-box;        /* Flexbox의 형식인 블록 컨테이너로 변환 */
        -webkit-box-orient: vertical; /* Flexbox를 수직으로 배치 */
        overflow: hidden;            /* 넘치는 텍스트를 숨김 */
        text-overflow: ellipsis;     /* 넘친 부분에 '...' 표시 */
        -webkit-line-clamp: 2;       /* 2줄까지만 표시 */
        color: #737373;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px; /* 128.571% */
      }

      > a {
        color: #10B981;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
      }
    }
  }
}

ul.link-list {
  margin-bottom: 24px;

  > li + li {
    margin-top: 10px;
  }

  a {
    display: block;
    width: 100%;
    height: 64px;
    border-radius: 8px;
    border: 1px solid rgba(147, 158, 173, 0.5);
    background: #fff;
    padding: 11px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    color: #242424;
    font-size: 16px;
    font-weight: 400;

    &:before {
      content: "";
      display: block;
      width: 40px;
      height: 40px;
    }

    &.mypage_03 {
      &:before {
        background: url(../../images/icon_org_chart.svg) no-repeat;
        background-size: 40px;
      }
    }

    &.mypage_04 {
      &:before {
        background: url(../../images/icon_merchant.svg) no-repeat;
        background-size: 40px;
      }
    }

    &.mypage_05 {
      &:before {
        background: url(../../images/icon_member.svg) no-repeat;
        background-size: 40px;
      }
    }
  }
}

div.image_upload {
  > span {
    display: block;
    color: #5D5F68;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px; 
    letter-spacing: 0.04px;  
  }

  > p {
    color: #696F8C;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04px;
  }

  > section {
    margin: 10px 0;

    > div {
      > p {
        margin-bottom: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        min-height: 56px;
        border-radius: 8px;
        border: 1px solid #2F66F6;
        background: #F2F7FF;
        color: #2F66F6;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px; /* 114.286% */
      
        &:before {
          content: "";
          display: block;
          width: 18px;
          height: 16px;
          background: url(../../images/icon_upload.svg) no-repeat;
          background-size: 18px 16px;
        }
      }
    }

    > aside {
      > div {
        position: relative;

        > div {
          position: relative;
          max-height: 110px;
          padding: 5px;

          > img {
            margin: 0 auto;
            display: block;
            max-height: 100px;
            position: relative;
            z-index: 2;
          }

          &:after {
            content: "";
            display: block;
            width :100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 10px;
            z-index: 1;
          }
        }

        > button {
          position: absolute;
          right: 10px;
          top: 10px;
          width: 20px;
          height: 20px;
          background: url(../../images/icon_close.svg) no-repeat center;
          background-size: 13px;
          overflow: hidden;
          text-indent: -9999px;
          z-index: 2;
        }

        & + div {
          margin-top: 10px;
        }
      }
    }
  }
}
